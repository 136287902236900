.faq {

	&.section {
		padding-bottom: 0;

		&:last-child {
			padding-bottom: 40px;
		}
	}

	&__item {
	    border-bottom: 1px solid $grey;
	    padding: 20px 0;
	}

    &__question {
		cursor: pointer;
		display: block;
		line-height: 1.8;
		position: relative;
		width: 100%; 

		&-text {
			color: #414042;
			display: block;
			font-size: 17px;
			padding-right: 25px;
			text-align: left;
		}

		&-arrow {
			line-height: 1.8;
			margin-top: 8px;
    		position: absolute;
    			top: 0;
    			right: 0;
		}
    }	

    &__answer {
		display: none;
		line-height: 1.65;
		padding-top: 6px;

		@include fromTablet {
			padding-top: 8px;
			padding-bottom: 1px;			
		}

		@include fromDesktop {
			padding: 15px 0 0;
		}

		.active {
			display: block;
		}	
    }

	&__help {
		font-size: 14px;
		display: block;
		margin: 20px 0;
	}    

}