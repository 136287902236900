.offer {
	color: $white;
	padding: 10px 0;

	&.green {
		background-color: $green;
	}

	&.blue {
		background-color: $blue;
	}

	&__border {
		border-top: 2px dashed $white;
		height: 2px;
	}

	&__row {
		display: flex;
		padding: 15px 0;
		align-items: center;
  		justify-content: center;

  		@include upToDesktop {
  			display: block;
  			text-align: center;
  		}
	}

	&__title {
		font-family: $heading-font;
		font-size: 26px;
		font-weight: 600;
		line-height: 30px;
	}

	p {
		margin-bottom: 0;
	}

	@include upToDesktop {
		p {
			margin-bottom: 20px;
		}
	}
}