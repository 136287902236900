.modal {
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    display: none;
    font-size: 16px;
    height: 100%;
    overflow: auto;    
    padding-top: 100px;
    position: fixed;
        left: 0;
        top: 0;
    width: 100%;
    z-index: 15;

    &__content {
        background-color: #fefefe;
        border: 1px solid #888;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
        margin: auto;
        max-width: 90%;
        padding: 40px;
        position: relative;
        width: 600px;
        animation-name: animatetop;
        animation-duration: 0.4s
    }

    &__close {
        color: #000;
        font-size: 28px;
        font-weight: bold;
        position: absolute;
            right: 10px;
            top: 10px;

        &:hover,
        &:focus {
            color: $blue;
            text-decoration: none;
            cursor: pointer;
        }    
    }

    a {
        color: $grey;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    } 
}

.country-modal {
    &__title {
        text-align: center;
        
        @include upToTablet {
            font-size: 21px;
        }
    }

    &__list {
        column-width: 200px;

        li {
            margin-bottom: 10px;

            img {
                margin-right: 5px;
                vertical-align: middle;
                width: 26px;
            }            
        }
    }

    &__link {
        color: $grey;
        font-size: 16px;
        font-style: italic;
        font-weight: 600;
        text-decoration: underline;
    }
}

.carsearch-modal,
.carsearch-modal-offer {
    &__body {
        background-color: $white;
        padding: 15px;
        position: relative;
    }

    &__title {
        text-align: center;
    }
}

/* Add Animation */
@-webkit-keyframes animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
}

@keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
}
