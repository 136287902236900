.widget_categories {
	li {
		display: inline-block;
		float: left;
		margin-right: 10px;

		a {
			background-color: $green;
			color: $white;
			display: inline-block;
			font-size: 14px;
			font-weight: 600;
			padding: 3px 8px;
		}
	}
}