body#tinymce {
 	margin: 12px !important;

	ul {
		@include ul;
	}

	a {
		color: $blue;
	}
}