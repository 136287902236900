.car-facts {
	background-repeat: no-repeat;
	background-size: cover;
	color: $white;
	position: relative;

	h3 {
		padding-bottom: 60px;
		text-align: center;
		z-index: 2; 
	}

	&__facts {
		font-weight: 700;
		z-index: 2; 

		&__fact {
			margin-bottom: 20px;
			width: 50%;

			.fact-icon {
				float: left;
				margin-right: 15px;
			}

			@include fromDesktop {
				&:nth-child(odd) {
					float: right;
					text-align: right;

					.fact-icon {
						float: right;
						margin-left: 15px;
					}
				}				
			}

			@include upToDesktop {
				width: 100%;
			}
		}
	}

	&__car {
		margin-top: 40px;
		margin-right: -50%;
		max-height: 250px;
		position: absolute;
	        top: 50%;
	        left: 50%;        
        transform: translate(-50%, -50%);
        z-index: 1;

        @include upToDesktop {
        	display: none;
        }
	}
}