.cta-hero {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	color: $white;
	position: relative;

	&__subtitle {
		color: $blue;
		font-size: 18px;
		font-weight: 700;
	}

	&__content {
		padding: 100px 0 40px;
	}

	@include upToTablet {
		.overlay {
			background-color: rgba(0, 0, 0, 0.67);
		    position: absolute;
			    top: 0;
			    left: 0;
			    bottom: 0;
		    width: 100%;
		    z-index: 0;
		}
	}
}