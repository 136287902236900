.partners {

	&__item {
		margin-bottom: 40px;

		@include upToTablet {
			text-align: center;
		}

		&-title {
			font-size: 17px;
		}

		a {
			color: $blue;
		}
	}

	&__map {
		height: 400px;
		width: 100%;
	}
}