#ballsWaveG {
  height: 28px;
  margin: auto;
  position: relative;
  width: 122px;

  &_1 {
    left: 0;
    animation-delay: 0.416s;
  }

  &_2 {
    left: 15px;
    animation-delay: 0.5225s;
  }

  &_3 {
    left: 30px;
    animation-delay: 0.619s;
  }

  &_4 {
    left: 46px;
    animation-delay: 0.7255s;
  }

  &_5 {
    left: 61px;
    animation-delay: 0.832s;
  }

  &_6 {
    left: 76px;
    animation-delay: 0.9385s;
  }

  &_7 {
    left: 91px;
    animation-delay: 1.035s;
  }

  &_8 {
    left: 106px;
    animation-delay: 1.1415s;
  }  
}

.ballsWaveG {
  background-color: rgba(235, 235, 235, 0.97);
  border-radius: 8px;
  height: 15px;
  position: absolute;
    top: 0;
  width: 15px;
  animation-name: ballsWaveG;
  animation-duration: 1.035s;
  animation-iteration-count: infinite;
  animation-direction: normal;
}

@keyframes ballsWaveG {
  0% {
    background-color: rgb(235, 235, 235);
  }
  100% {
    background-color: rgb(255, 255, 255);
  }
}