.tc_product_filter a.submit-filter {
	background-color: $green !important;
	height: 52px !important;
	line-height: 52px !important;
    padding: 0 18px !important;
    font-family: Poppins,sans-serif;
    font-size: 17px;
    font-weight: 500;
    display: inline-block !important;
    border: 2px solid #17a001;
    text-decoration: none;
}

.tc_product_filter label.filter_input_label:after {
	background: #17a001 !important;
	background: url('../images/icon-arrow-white.svg') no-repeat 50% #17a001 !important;
	border-left: 1px solid #58595b;
	content: " " !important;
	display: block;
	float: left;
	min-height: 100%;
	position: absolute;
		top: 0 !important;
		right: 0 !important;
	width: 45px;
}

.tc_product_filter .filter_input {
	font-size: 16px !important;
}
.tc_product_filter .selects-wrapper {
    width: 85% !important;
}
