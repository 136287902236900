.footer {
	background-color: $dark;
	color: $white;
	font-size: 15px;
	line-height: 24px;
	padding-top: 30px;

	h3 {
		font-size: 21px;
		line-height: 24px;
	}

	a {
		color: $white;
		font-weight: 600;

		&:hover {
			color: $blue;
		}
	}

	&__categories {
		background-color: $very-light-grey;

		&-title {
			margin-bottom: 50px;
			position: relative;
			
			@include borderBottomLeft; 
		}

		a {
			color: $grey;
		}
	}

	&__columns {
		column-width: 150px;
	}

	section {
		padding: 0;

		@include upToTablet {
			padding-top: 15px;
		}
	}
	.social_icons {
		margin-bottom: 16px;
		
		.col-md-2{
			padding: 0px;
		}

		.facebook{
			padding-left: 15px;
		}
	}
}

.subfooter {
	background-color: $black;
	color: $light-grey;
	font-size: 12px;
	line-height: 40px;
	height: 40px;
	margin-top: 20px;

	a {
		color: $light-grey;
	}

	&__copyright {
		float: left;
	}

	&__navigation {
		float: left;
		font-weight: 500;

		li {
			display: inline-block;
			margin-left: 10px; 
			padding-left: 10px;
			position: relative;

			&:before {
				color: $light-grey;
				content: ' | ';
				position: absolute; 
					left: 0;
				width: 10px;
			}
		}
	}

	@include upToTablet {
		height: auto;
		min-height: 40px;

		&__navigation {
			display: block;
			width: 100%;

			li {
				padding-left: 0;

				&:first-child {
					margin: 0;
				}

				&:before {
					content: '';
					width: 0;
				}
			}
		}
	}
}