// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

$small-width:		320px;
$phone-width:       480px;
$tablet-width:      768px;
$desktop-width:     1024px;
$wide-width:        1200px;

$header-height:		60px;
$topbar-height:		36px;
$submenu-height:	32px;

$blue:				#47a6dc;
$green:				#17a001;
$orange:			#ff4800;

$dark-green:		#158603;
$white:				#fff;
$black:				#000;
$dark:				#090909;
$grey:				#414042;
$light-grey: 		#dfdfdf;
$alt-light-grey:	#e6e7e9;
$very-light-grey:	#f1f2f2;
$border-grey:		#58595b;

$primary-font: 		'Hind', sans-serif;
$heading-font:		'Poppins', sans-serif;
