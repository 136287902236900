.cta-small {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;	
	color: $white;
	text-align: center;

	&__subtitle {
		font-size: 20px;
	}

	&__item {
		&:after {
			background-color: $white;
			content: '';
			height: 100px;
			position: absolute;
				right: 0;
				top: 0;
			width: 1px;
		}		

		&:last-child {
			&:after {
				width: 0;
			}
		}	

		@include upToDesktop {
			&:after {
				height: 0;
				width: 0;
			}
		}	
	}
}