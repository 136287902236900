.two-columns-wide {
	display: flex;
	flex-wrap: wrap;
	padding: 0;

	ul {
		@include ul;
	}
	
	&__column {
		display: flex; 
		width: 50%;

		@include upToDesktop {
			display: block;
			width: 100%;
		}
	}

	&__item {
		padding: 5%;
		width: 100%;

		.btn {
			margin-top: 15px;
		}
	}

	&--grey {
		background-color: $very-light-grey;
	}

	&--blue {
		background-color: $blue;
		color: $white;

		.btn {
			border-color: $white;
			color: $white;
		}
	}

	&--white {
		background-color: $white;

		a {
			color: $blue;

			&.btn {
				color: $white;
			}
		}
	}
}
