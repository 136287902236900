.header {
	border-bottom: 2px solid $blue;
	height: $header-height;
	line-height: $header-height;
	position: fixed;
		top: $topbar-height;
	width: 100%;
	z-index: 15;

	&-dark {
		background-color: $dark;
		color: $white;

		a {
			color: $white;
		}
	}

	&-light {
		background-color: $white;
		color: $grey;

		a {
			color: $grey;
		}
	}

	&__logo {
		vertical-align: middle;
	}

	&__menu {

		li {
			display: inline-block;
			margin-right: 20px;
			position: relative;

			a:hover, &.current-menu-item > a {
				color: $blue;
			}

			.sub-menu {
				box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.5);
				display: none;
				position: absolute; 
					left: 0;
					top: $header-height;
				width: 250px;
				z-index: 2;

				li {
					display: block;
					font-family: $primary-font;
					font-size: 16px;
					font-weight: 400;
					margin: 0;

					a {
						background-color: $white;
						color: $dark;
						display: block;
						height: $submenu-height;
						line-height: $submenu-height;
						padding-left: 10px;

						&:hover {
							background-color: $blue;
							color: $white;
						}
					}
				}

			}

			&:hover > .sub-menu {
				display: block;
			}

		}
	}

	&__woocommerce {
		text-align: right;

		a {
			display: inline-block;
			position: relative;
		}

		img {
			padding-left: 15px;
			vertical-align: middle;
		}

		&-count {
			position: absolute;
				top: -7px;
				left: 21px;
		}
	}
}

.mobile-header {
	background-color: $dark;
	color: $white;	
	height: 60px;
	line-height: 60px;
	position: fixed;
		top: 0;
	width: 100%;
	z-index: 12;
	
	a {
		color: $white;
	}	

	img {
		vertical-align: middle;
	}

	&__list {
		display: flex;
		width: 100%;

		&__item {
			border-right: 1px solid $grey;
			display: inline-block;
			float: left;
			height: 60px;
			text-align: center;
			
			&--button {
				width: 60px;
			}

			&--logo {
				flex-grow: 1;
			}

			&:last-child {
				border: none;
				position: relative;
			}
		}		
	}
}

.topbar {
	background-color: $light-grey;
	font-size: 14px;
	font-weight: 500;
	height: $topbar-height;
	line-height: $topbar-height;
	position: fixed;
		top: 0;
	width: 100%;
	z-index: 11;

	&__discount {
		font-family: $heading-font;
		font-size: 13px;
		font-style: italic;
		font-weight: 700;
		text-align: center;
	}

	&__language {
		text-align: right; 

		&-flag {
			cursor: pointer;
			margin-right: 4px;
			margin-top: -2px;
			vertical-align: middle;
		}

		&-country {
			margin-right: 15px;
			cursor: pointer;
		}

		&-currency {
			float: right;
			max-width: 100px;
			text-align: left;
			z-index: 11;

			select {
				background-color: $white;
			}
		}
	}
}

.lang-btn-mobile {
    z-index: 9999;
    color: #ffffff;
    width: 100%;
	text-align: center;
	font-size: 15px;
	
	img{
		margin-right: 6px;
		margin-bottom: 3px;
	}

	.topbar__language-currency {
		display: inline-table;
		float: none;
	}

	select.woocommerce-currency-switcher {
		height: 28px;
		background: #090909;
		color: white;

		option {
			height: auto;
			font-size: 11px;
		}
	}

}