.woocommerce {

	label {
		display: block;
	}

	.enable-local-pickup, 
	.disable-local-pickup {
		color: $blue;
		font-size: 17px;
		text-decoration: underline;
	}

	.disable-local-pickup {
		display: block;
		margin-top: 10px;
	}

	.input-text {
		background: $white;
		border: 1px solid $light-grey;
		font-size: 14px;
		height: 35px;
		outline: none;
		padding: 0 10px;
		width: 100%;
	}

	.show-coupon {
		font-weight: 700;

		a {
			color: $blue;
			font-weight: 500;
		}
	}

	button,
	.button {
		background-color: $green;
		border: none;
		color: $white;
		cursor: pointer;
		display: inline-block;
		font-family: $heading-font;
		font-size: 17px;
		font-weight: 500;
		padding: 6px 20px 2px;
		text-transform: uppercase;

		&:hover {
			background-color: $dark-green;
			color: $white;
		}
	}

	&-error, 
	&-info, 
	&-message {
	    background-color: $very-light-grey;
	    border-left: 5px solid #a46497;
		color: #515151;
		list-style: none outside;
		margin: 0 0 2em;
	    padding: 1em 2em 1em 3.5em;
	    position: relative;
	    width: auto;

		&::after, 
		&::before {
		    content: ' ';
		    display: table;
		}	 

		&::before {
			background-image: url('../images/icon_info.svg'); 
			background-repeat: no-repeat;
		    content: '';
		    display: inline-block;
		    position: absolute;
			    top: 1em;
			    left: 1.5em;
			height: 22px;
			width: 22px;
		}

		&::after {
		    clear: both;
		}

		a {
			color: $blue;
		}

		li {
		    list-style: none outside!important;
		    padding-left: 0!important;
		    margin-left: 0!important;
		}

		.button {
			float: right;
		}
	}

	&-info {
		border-left-color: #1e85be;

		&::before {
    		color: #1e85be;
		}
	}

	&-error {
	    border-left-color: #ff0000;

	    &::before {
		    background-image: url('../images/icon_error.svg'); 
		}
	}

	&-message {
	    border-left-color: $green;

	    &::before {
	    	background-image: url('../images/icon_check.svg'); 
		}

		.wc-forward {
			background-color: $grey !important;
			float: right !important;
		}
	}

	&-form-login-toggle {
		.woocommerce-info {
			background-color: $green;
			border: 0;
			color: $white;

			a {
				color: $white;
				text-decoration: underline;
			}

		    &::before {
			    background-image: url('../images/icon_login-white.svg'); 
			}			
		}
	}

	&__archive {
		&-title {
			font-size: 32px;
			line-height: 38px;
		}
	}

	.cart-info {
		margin-bottom: 10px;

		&__title {
			margin-bottom: 60px;
			position: relative;
			@include borderBottomLeft;

			@include upToDesktop {
				margin-bottom: 30px;
			}
		}

		&__item {
			display: flex;
			margin-bottom: 20px;
			align-items:center;

			img {
				margin: 0;
				margin-right: 15px;
				vertical-align: middle;
			}

			&-text {
				color: $green;
				font-weight: 500;
				line-height: 20px;
				text-transform: uppercase;
			}

			@include upToDesktop {
				display: none;
			}
		}
	}

	&-cart-form {
		&__contents {
			width: 100%;

			&-head {
				background: $blue;
				color: $white;
				line-height: 40px;
			}
		}

		.product-thumbnail {
			text-align: center;

			img {
				max-height: 60px;
				width: auto;
			}	
		}

		.product-remove {
			font-size: 24px;
		}

		.product-name {
			a {
				color: $blue;
				text-decoration: underline;
			}
		}

		.quantity {
			text-align: center;

			input.qty {
				color: $grey;
				text-align: center;
				width: 50px;
			}
		}

		.coupons {
			display: none !important;
		}
	}

	&-my-account {
		h3 {
			margin-bottom: 40px;
			position: relative;
			@include borderBottomLeft;
		}

		&__dashboard {
			a {
				color: $blue;
			}
		}

		.is-active {
			a {
				color: $blue;	
			}
		}

		a {
			&:hover {
				color: $blue;
			}
		}

		table {
			border-collapse: collapse;

			thead {
				background-color: $blue;
				color: $white;
			}			

			.woocommerce-button {
				font-family: $heading-font;;
			    font-weight: 500;				
				margin-right: 10px;
				text-transform: uppercase;

				&:hover {
					color: $white;
				}
			}
		}

		button {
			margin-top: 20px;
		}
	}

	&-Address {
		a {
			color: $blue;
		}
	}

	.lost_password {
		margin-top: 20px;

		a {
			color: $blue;
			font-size: 15px;
			text-decoration: underline;
		}
	}

	&-checkout-payment {
		img {
			float: right;
			vertical-align: middle;
		}

		.wc_payment_method {
			margin-bottom: 5px;
			overflow: auto;

			&.payment_method_paypal img {
			    height: 25px;
			}

			&.payment_method_stripe input {
				position: relative !important;
				top: 0px !important;
			}

			&::after {
			    content: "";
			    clear: both;
			    display: table;
			}
		}

		.payment_methods {
			@include remove_ul;

				li {
				margin-bottom: 5px;

					label {
					// border: 1px solid #dedede;
					padding: 10px;
					cursor: pointer;
					}

					input {
					margin-top: 10px;
					}
			} 
		}
	
		button {
			display: block;
			font-size: 1.25em;
			margin-bottom: 1em;
			padding: 1em;
			text-align: center;
			width: 100%;
		}
	}	

	form {
		&.checkout_coupon, 
		&.login, 
		&.register {
			border: none;
		}

		.form-row {
		    padding: 3px;
		    margin: 0 0 6px;

		    &.woocommerce-invalid {
		    	label,
		    	.select2-container, 
				input.input-text, 
				select {
				    border-color: #a00;
				}	
			}

			&.woocommerce-validated {
				.select2-container, 
				input.input-text,
				select {
			    	border-color: #69bf29;
			    }
			}	
		}

		.form-row-wide {
		    clear: both;
		}

		.form-row-first, 
		.form-row-last {
		    width: 47%;
		    overflow: visible;

		    @include upToDesktop {
		    	width: 100%;
		    }
		}

		.form-row-first {
		    float: left;
		}

		.form-row-last {
		    float: right;
		}

		.required {
			border: 0;
			color: #ff0000;
			font-weight: 500;
		}
	}

	&-account {

		.woocommerce::after, 
		.woocommerce::before {
		    content: ' ';
		    display: table;
		}

	}

	.shop_table {
	    margin: 0 -1px 24px 0;
	    text-align: left;
	    width: 100%;

	    thead {
	    	background-color: $blue;
			color: $white;

			th {
				height: 47px;
			}
	    }

		th {
			padding: 4px 8px;

			&:last-child {
				padding-right: 10px;
			}
			&.no-padding {
				padding: 0 !important;
			}
		}		    

	    td {
		    border-top: 1px solid rgba(0,0,0,.1);
		    padding: 6px 8px;
		    vertical-align: middle;
		}

		tbody:first-child tr:first-child td, 
		tbody:first-child tr:first-child th {
			border-top: 0;
		}	
	}

	&.my_account_orders {

		td,
		th {
		    padding: 4px 8px;
			vertical-align: middle;
		}
	}

	&.cart,
	.cart {
		td, 
		th {
	    	vertical-align: middle;
	    }

	    td.coupons {
		    .input-text {
			    width: 250px;
			}

		    .coupon {
			    float: left;

			    label {
				    display: none;
				}

				.input-text {
				    float: left;
				    box-sizing: border-box;
				    border: 1px solid #d3ced2;
				    padding: 6px 6px 5px;
				    margin: 0 4px 0 0;
				    outline: 0;
				    line-height: 1;
				}
			}			    	
	    }

	    td.actions {
		    text-align: right;

		    button {
		    	background-color: $grey;
		    }
		}
	}

	.cart-collaterals {
	    width: 100%;

	    &::after, 
		&::before {
		    content: ' ';
		    display: table;
		}

		.cart_totals {
		    float: right;
		    width: 48%;

		    table {
		    	background-color: $very-light-grey;
			    border-collapse: separate;
			    margin: 0 0 6px;
			    padding: 0;

			    td {
			    	text-align: right;
			    }

			    th, 
			    td {
			    	border-top: 1px solid $light-grey;
			    	padding: 10px;
			    }
			}
		}
	}

	.wc-proceed-to-checkout {
	    padding: 1em 0;

	    a.checkout-button {
		    display: block;
		    text-align: center;
		    margin-bottom: 1em;
		    font-size: 1.25em;
		    padding: 1em;
		}
	}

	/**
	* Checkout *
	**/ 
	&-checkout {
		.methods {
			.input-radio {
				float: left;
				margin-right: 10px;
			}
		}

		.woocommerce-shipping-fields {
			margin-bottom: 15px;
			padding-left: 3px;
		}

		#shipping_method {

			@include remove_ul;

			input {
			  float: left;
			  margin-left: 0;
			  clear: both;
			}

			label {
			  display: inline-block;
			  font-size: 16px;
			  font-weight: lighter;
			  vertical-align: baseline;
			  padding-top: 6px;
			  padding-left: 4px;
			}
		}

		.shipping_method {
		  font-family: $heading-font;
		  font-size: 30px;
		  font-weight: 700;
		  line-height: 110%;
		  margin-top: 15px;
		  margin-bottom: 10px;
		}	

		.col-md-6:first-child h3:after,
		h3#order_review_heading:after,
		h3.payment__title:after {
		  background-color: $blue;
		  content: "";
		  height: 2px;
		  width: 110px;
		  display: table;
		}

		.product-thumbnail img {
		  max-height: 60px;
		  width: auto;
		}

		th.product-total,
		td.product-total {
		  text-align: right;
		}

		h3#order_review_heading {
		  margin-top: 10px;
		}
		
		.methods .input-radio {
		  float: left;
		  margin: 15px;
		}
		
		fieldset#wc-stripe-cc-form {
		  margin-top: 10px;
		}

		label[for="wc-stripe-new-payment-method"] {
		  border: none !important;
		}
	}

	/*
	** Order Details 
	*/
	&-order-details {
		padding-top: 20px;
	}

	input#submit.disabled, 
	a.button.disabled, 
	button.button.disabled, 
	input.button.disabled {
	    color: inherit;
	    cursor: not-allowed;
	    opacity: .5;
	    padding: .618em 1em;
	}

	.button, 
	input.button {
	    float: none;
	}

	a.remove {
	    display: block;
	    font-size: 1.5em;
	    height: 1em;
	    width: 1em;
	    text-align: center;
	    line-height: 1;
	    border-radius: 100%;
	    color: #ff0000 !important;
	    text-decoration: none;
	    font-weight: 700;
	    border: 0;
	}

	.mobile-dashboard {
		position: fixed;
			right: 0;
			bottom: 100px;
		z-index: 10;

		&__content {
			background-color: $white;
			display: none;
			padding: 15px;
		}

		&__nav {
			cursor: pointer;
			text-align: right;
		}
	}
	
	&__checkout-information {
		@include upToDesktop {
			display: none;
		}

		&__mobile {
			@include fromDesktop {
				display: none;
			}
		}

		&__content {
			display: none;
		}

		&-link {
			color: $blue;
			display: block;
			margin: 15px 0;
			text-decoration: underline;
		}
	}		
}


p#is_company_field {
  clear: both;
}


p#billing_options_field {
  clear: both;
}


p#billing_company_field {
  display: none;
}

@include upToDesktop {

	.woocommerce {

		table {
			&.shop_table_responsive {
				thead {
					display: none;
				}

				tbody th, 
				tbody th {
				    display: none;
				}	

				tr {
					display: block;

					td {
						display: block;
	    				text-align: right!important;

	    				&::before {
						    content: attr(data-title) ": ";
						    font-weight: 700;
						    float: left;
						}

						&.actions, 
						&.product-remove {
							&::before {
								display: none;
							}
						}

						&.product-remove {
							display: none;
						}
					}
				}

				tr:first-child td:first-child {
				    border-top: 0;
				}	
			}

			&.woocommerce-checkout-review-order-table {
				tbody th, 
				tbody th {
				    display: inline-block;
				    float: left;
				}	

				tr {
					td {
						&:before {
							content: '';
						}
					}
				}				
			}

			&.cart {

				td.actions {
					.coupon {
					    float: none;
					    padding-bottom: .5em;

					    .button.alt, 
						.input-text+.button {
						    float: right;
						}

						&::after, 
						&::before {
						    content: ' ';
						    display: table;
						}
					}

					.button {
					    display: block;
					    width: 100%;
					}	
				}

				.product-thumbnail {
				    display: none;
				}

				.quantity {
					text-align: right;
				}
			}
		}

		.cart-collaterals {

			.cart_totals, 
			.cross-sells, 
			.shipping_calculator {
			    width: 100%;
			    float: none;
			    text-align: left;
			}
		}
	}
}

/* Products */
.products {

	&__list {
		@include remove_ul;

		margin: 0 !important;

		li {
			list-style: none !important;
		}
	}

	&__content {
		margin: 18px 5px 0;

		&-description {
			color: $grey;
			font-size: 17px;
    		font-weight: 500;
		}

		&-list {
			list-style: none;
			margin: 0 !important;

			li {
				list-style: none !important;
				font-weight: 400;
				margin-bottom: 20px;
				margin-left: 30px;
				position: relative;

				&:before {
					content: '';
					background-image: url('../images/icon_check.svg'); 
					background-repeat: no-repeat;
					height: 22px;
					position: absolute;
						left: -30px;
					width: 22px;
				}
			}
		}		
	}

	&__item {

		.product {

			&-title {
				font-family: $heading-font;
				font-size: 40px;
				font-style: italic;
				font-weight: 700;
				text-align: center;
				text-transform: none;
			}

			&-meta {
				font-size: 30px;
				position: absolute;
					bottom: 0;
				text-align: center;
				width: 100%;

				&__item {
					float: left;
					padding: 10px 0;
					width: 50%;

					&-fat {
						color: $blue;
						font-family: $heading-font;
						font-size: 30px;
					}

					&-thin {
						font-size: 30px;
						font-weight: 300;
					}				
				}
			}

			&-buy {
				background: $very-light-grey;
				height: 73px;
				padding: 10px 15px;
				position: relative;

				del {
					color: $white;
					display: block;
					font-family: $heading-font;
					font-size: 18px;
					text-decoration: line-through;					
				}

				ins {
					color: $white;
					display: block;
					font-family: $heading-font;
					font-size: 30px;
					text-decoration: none;
				}				
				
				&__sale {
					font-family: $heading-font;
					font-size: 18px;
				}

				&__nosale {
					font-size: 30px;
					line-height: 63px;
				}

				&__currency {
					font-family: $primary-font;
					font-size: 30px;
					font-weight: 300;
				}

				&__button {
					background: $green;
					border-left: 1px solid $very-light-grey;
					color: $white;
					display: inline-block;
					font-family: $heading-font;
					font-weight: 600;
					line-height: 73px;
					padding: 0 30px;
					position: absolute;
						top: 0;
						right: 0;
					text-align: center;
					text-transform: uppercase;
				}
			}			
		}
	}	

	&__sale {

		.product {
			&-title {
				color: $white;
			}

			&-meta {

				&__item {
					&-fat {
						color: $white;
					}
					&-thin {
						color: $white;
					}
				}
			}

			&-buy {
				background: $orange;

				&__sale {
					color: $white;
				}

				&__price {
					color: $white;
				}

				&__currency {
					color: $white;
				}
			}			
		}

		.triangle {
			border-bottom: 70px solid transparent;
			border-left: 70px solid $orange;
			height: 0;
			position: absolute;
				top: 0;
				left: 0;
			width: 0;
			z-index: 3;
		}

		.sale {
			color: #fff;
			font-family: $heading-font;
			font-weight: 700;
			position: absolute;
			    top: 15px;
			    left: 0;
			text-align: center;
			text-transform: uppercase;
			width: 50px;
			transform: rotate(-45deg);
			-webkit-transform: rotate(-45deg);	
			z-index: 3;		
		}
	}	
	
	&__top {
		background-repeat: no-repeat;
		background-position: center;
		position: relative;

		&--sale {
			&:after {
			    background: -webkit-linear-gradient(transparent,#000);
			    background: linear-gradient(transparent,#000);
			    content: '';
			    display: block;
			    height: 20%;
			    opacity: 1;
			    position: absolute;
			    	bottom: 0;
			    width: 100%;
			}			
		}

		&__content {
			height: 360px;
			padding-top: 10px;
			position: relative;
			z-index: 2;
		}
	}	
}

.wc {
	&-info {
		background: $black;
		color: $white;

		&__item {
			margin: 30px 0;
			padding: 0 20px;
			text-align: center;

			&:last-child {

				&::after {
					background-color: transparent;
					height: 0;
					width: 0;	
				}
			}

			&-text {
				font-weight: 600;
			}

			&::after {
				background-color: $white;
				display: block;
				content: ' ';
				position: absolute;
					top: 0;
					right: 0;
				height: 120px;
				width: 1px;
			}

			@include upToDesktop {
				&::after {
					background-color: transparent;
					height: 0;
					width: 0;	
				}
			}
		}
	}

	&-benefits {
		padding: 30px 0;
		text-align: center;

		&-title {
			margin-bottom: 60px;
			position: relative;
			@include borderBottomCenter;
		}

		&__item {
			margin-bottom: 20px;
		}
	}
}

#checkout_cart input#coupon_code {
  width: auto;
}

/* Style to overcome jQuery dialog's inline styling on the coupon dialog (Optional) */
.ui-dialog.ui-widget.ui-widget-content.ui-corner-all.ui-front.coupon-special {
  border: 0;
  position: relative;
	  top: 0 !important;
	  left: 0 !important;
}

/* Remove the close coupon field button (Optional) */
.ui-widget-header {
	display: none;
}

@include fromDesktop {

	fieldset#wc-stripe-cc-form>div:first-child {
		width: 100%;
	}

	p#billing_address_1_field {
		float: left;
	}

	p#billing_address_2_field {
		float: right;
	}

	.checkout .woocommerce-billing-fields h3 {
		margin-top: 0;
	}

	body .woocommerce .form-row-wide .col-1 {
		width: 100%;
	}

	:not(.woocommerce-shipping-fields)+.checkout .form-row-wide {
		width: 47%;
	}

	.checkout p#billing_address_1_field {
		clear: both;
	}

	.checkout .form-row-wide:nth-child(even) {
		float: right;
	}

	.checkout .form-row-wide:nth-child(odd) {
		float: left;
	}

	.checkout form .form-row-wide {
		clear: none;
	}

	.checkout p#shipping_country_field {
		max-height: 70px !important;
		height: 65px;
	}

	.checkout .woocommerce-shipping-fields {
		clear: both;
	}

	p#billing_address_2_field,
	p#shipping_address_2_field {
		height: 65px;
		padding-top: 27px;
	}

	.create-account {
		clear: both;
	}

	p.form-row.form-row-wide.create-account.woocommerce-validated {
		clear: both;
	}

	p#billing_country_field {
		width: 100%;
	}

	p#billing_postcode_field {
		float: left !important;
	}

	p#billing_city_field {
		float: right !important;
	}

	p#billing_address_google_field {
		width: 100% !important;
	}

	p#shipping_postcode_field {
		float: right !important;
	}

	p#shipping_country_field {
		width: 100% !important;
	}

	label[for="billing_address_google"] abbr {
		display: none;
	}

	.bg-search section:first-child {
		display: none !important;
 	}
}

div#Flash {
    clear: left;
    margin-top: 15px;
}

button#place_order {
	padding: 20px 20px;
	margin-top: 20px;
}