.three-icons {
	background-color: $dark;
	color: $white;

	&__icon {
		display: block;
		float: left;
		margin-right: 15px;
	}

	&__text {
		display: block;
		float: left;
	}
}