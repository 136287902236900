/**
 *
 * FONT SMOOTHING
 *
 * Changes the font rendering,
 * allowing thinner fonts
 */

@mixin fontSmoothing {
  text-shadow: 1px 1px 1px rgba(0,0,0,.004);
  text-rendering: optimizeLegibility!important;
  -webkit-font-smoothing: antialiased!important;
}

/**
 * MEDIA QUERIES
 * -------------
 */

@mixin phoneOnly {
  @media (max-width: #{$phone-width - 1px}) {
    @content;
  }
}

@mixin upToTablet {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin upToDesktop {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin upToWide {
  @media (max-width: #{$wide-width - 1px}) {
    @content;
  }
}

@mixin fromSmall {
  @media (min-width: #{$small-width} ) {
    @content;
  }
}

@mixin fromTablet {
  @media (min-width: #{$tablet-width} ) {
    @content;
  }
}

@mixin fromDesktop {
  @media (min-width: #{$desktop-width}  ) {
    @content;
  }
}

@mixin fromWide {
  @media ( min-width: #{$wide-width} ) {
    @content;
  }
}

@mixin borderBottomLeft {
  &:after {
    background-color: $blue;
    content: '';
    height: 2px;
    position: absolute;
      left: 0;
      bottom: 0;
    width: 130px;
  }  
}

@mixin borderBottomCenter {
  &:after {
    background-color: $blue;
    content: '';
    height: 2px;
    margin-left: -110px;
    position: absolute;
      left: 50%;
      bottom: 0;
    width: 220px;
  }  
}

@mixin ul {
  margin: 17px;

  li {
    list-style: disc;
  }
}

@mixin remove_ul {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    margin: 0;
    padding: 0;
  } 
}