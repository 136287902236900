.testimonials {

	&__title {
		margin-bottom: 50px;
		position: relative;
		@include borderBottomLeft;
	}

	&__item {
		text-align: center;

		img {
			border-radius: 50%;
			max-width: 130px;
		}

		&-title {
			font-weight: 600;
		}

		&-name {
			color: $green;
		}
	}
}