.btn {
	border: 2px solid $grey;
	color: $grey;
	display: inline-block;
	font-family: $heading-font;
	font-size: 17px;
	font-weight: 500;
	padding: 6px 30px 2px;
	text-transform: uppercase;

	&--green {
		background-color: $green;
		border-color: $green;
		color: $white !important;

		&:hover {
			background-color: $dark-green;
		}
	}

	&--blue {
		background-color: $blue;
		border-color: $blue;
		color: $white !important;

		&:hover {
			background-color: $blue;
		}
	}

	&--white {
		border-color: $white;
		color: $white;
	}
}

.gform_button {
	background-color: $green;
	border: 2px solid $green;
	color: $white;
	cursor: pointer;
	display: inline-block;
	font-family: $heading-font;
	font-size: 17px;
	font-weight: 500;
	padding: 6px 30px 2px;
	text-transform: uppercase;

	&:hover {
		background-color: $dark-green;
	}
}

input[type='submit'] {
	border-radius: 0px;
	appearance: none;  
}