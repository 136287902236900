.latest-posts {
	&__title {
		position: relative;
		@include borderBottomLeft;
	}

	&__news {
		margin-bottom: 30px;
		position: relative;

		img {
			margin-bottom: 15px;
		}
		
		&-title {
			padding-bottom: 5px;
			position: relative;
			@include borderBottomLeft;
		}

		&-link {
			display: block;
			position: absolute;
				top: 0;
				bottom: 0;
			width: 100%;
			z-index: 2;
		}
	}
}