body {
	background-color: #fff;
	color: $grey;
	font-family: $primary-font;
	font-smoothing: antialiased; 	
	font-size: 17px;
	font-weight: 300;
	height: 100%;
	line-height: 24px;
	margin: 0;
	padding: 0;

	@include fromDesktop {
		margin-top: $header-height + $topbar-height;
	}

	@include upToDesktop {
		margin-top: $header-height;
	}
}

body.single-product {
	@include upToDesktop {
		margin-top: 49px;
	}
}

html .single-product .main {
	@include upToDesktop {
		padding-top: 0;
	}
}

body, html { 
	min-height: 100%; 
	position: relative;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}

img {
	height: auto;
	max-width: 100%;
}

figure {
	height: auto;
	margin: 0;
	max-width: 100%;
	padding: 0;
	position: relative;
}

figcaption {
	background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5);
	border-bottom: 4px solid $blue;
	color: $white;
	font-size: 14px;
	font-style: italic;
	font-weight: 600;
	padding: 4px 10px;
	position: absolute;
		bottom: 6px;
	width: 100%;
}

blockquote {
	background-color: $very-light-grey;
	color: $green;
	font-family: $heading-font;
	font-size: 26px;
	font-style: italic;
	font-weight: 600;
	line-height: 32px;
	margin: 0 auto;
	margin-bottom: 15px;
	padding: 20px 15px;
	text-transform: uppercase;
	width: 80%;

	p {
		margin-bottom: 0;
	}
}

ul {
	@include remove_ul;
}

ol li {
	list-style: decimal;
	margin-bottom: 10px;
}

a {
	color: $blue;
	text-decoration: none;
}

p {
	margin-top: 0;
}

abbr {
	text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $heading-font;
	font-weight: 700;
	margin-top: 0;
	margin-bottom: 15px;
}

h1 {
	font-size: 40px;
	line-height: 52px;
}

h2 {
	font-size: 40px;
	line-height: 48px;

	@include upToTablet {
		line-height: 42px;
	}
}

h3 {
	font-size: 30px;
	line-height: 40px
}

h4 {
	font-size: 24px;
	line-height: 30px;
}

.section {
	padding: 40px 0;
}

iframe {
	max-width: 100%;
}

.reverse {
	display: flex;
	flex-direction: row-reverse;

	@include upToDesktop {
		flex-direction: column-reverse;
	}

	& > div {
		width: 50%;

		@include upToDesktop {
			width: 100%;
		}
	}
}

.float-right {
	float: right;
}

.arrow {

	&-down,
	&-up {
		background-image: url('../images/arrow.svg');
		background-position: center;
		background-repeat: no-repeat;
		display: block;
		height: 15px;
		width: 25px;
	}	

	&-up {
		transform: rotate(180deg);
	}
}

.align {
	&-left {
		text-align: left;
	}

	&-right {
		text-align: right;	
	}

	&-center {
		text-align: center;
	}
}


img {
	&.alignright { 
		float: right; 
		margin: 0 0 1em 1em; 
	}
	&.alignleft { 
		float: left; 
		margin: 0 1em 1em 0; 
	}
	&.aligncenter { 
		display: block; 
		margin-left: auto; 
		margin-right: auto; 
	}
}

.row.is-flex {
    display: flex;
    flex-wrap: wrap;
}

.row.is-flex > [class*='col-'] {
    display: flex;
    flex-direction: column;

    @include upToDesktop {
    	width: 50%;
    }

    @include upToTablet {
    	width: 100%;
    }
}

#map {
	height: 100%;
}

.clear {

	&:before,
	&:after {
		content: " ";
    	display: table;
	}

	&:after {
	    clear: both;	    
	}
}

.g-recaptcha {
	margin-bottom: 20px;
}

/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}
