.carsearch {
  div[class*="col-"] {
    transition: .4s;
  }

  span.col-xs-3 {
    background: #d2d3d5;
    border: 1px solid $border-grey;
    border-left: none;
    cursor: pointer;
    display: none;
    float: right;
    font-size: 20px;
    padding: 10px;
    text-align: center;
  }
}

.carsearch-result {

  &__price {
    del {
      display: block;
    }
  }

  @include upToTablet {
    margin-bottom: 10px;

    &__title {
      color: $white;
      text-align: center !important;

      .headline {
        line-height: 1;
      }

      .sub-headline {
        font-size: 0.5em;
        font-weight: 700;
        line-height: 0;
      }
    }

    &__subtitle {
      color: $blue;
      text-align: center;
    }
  }

  @include upToDesktop {
    background-image: url(/wp-content/themes/glx-theme/dist/images/search-result_background.jpg);
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.label-car-search {
  border: 1px solid $border-grey;
  padding: 0;
  cursor: pointer;
  padding-left: 15px;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ui-combobox {
  position: relative;
}

.ui-combobox-input {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  padding: 12px 6px;
  width: 100%;
}

.ui-combobox-input:focus {
  cursor: inherit;
}

.ui-combobox-input.ui-input-invalid {
  border-color: red;
  color: red;
}

.ui-icon-triangle-1-s {
  cursor: pointer;
}

.ui-autocomplete {
  font-size: 95%;
  background: white;
  max-height: 300px;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 5;

  li {
    cursor: pointer;
    padding: 0 15px;

    &:hover {
      background: #17a001;
      width: 100%;
      color: white;
    }

    .ui-state-focus {
      font-weight: normal;
    }
  }
}

span.ui-helper-hidden-accessible {
  display: none !important;
}

a.repo {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.active_products {
  display: none;

  .cols {
    display: none;
  }
}

.section_car_result {
  display: none;
}

input[disabled] {
  background-color: #dedede;
  -webkit-text-fill-color: rgb(45, 45, 45) !important;
  -webkit-opacity: 1 !important;
  box-shadow: none !important;
  border: 1px solid #bdbdbd;
  cursor: unset;
}

section.resultsection {
  padding: 0;
}

.search-section {
  background-color: #e6e7e9;
  padding: 20px 0;
}

.formcarsearch .ui-icon:before {
  content: " ";
  position: absolute;
  background: #dfdfdf;
  right: 0;
  border: 1px solid #58595b;
  display: block;
  background: url(/wp-content/themes/glx-theme/dist/images/arrow.svg) no-repeat center #dfdfdf;
  min-height: 100%;
  width: 45px;
  float: left;
  top: 0;
}

select#makes {
  width: 100%;
}

.search-module {
  padding-bottom: 5px;

  input:valid+.ui-icon:before {
    content: " ";
    background: url('../images/icon-arrow-white.svg') no-repeat center #17a001;
  }

  input:invalid + .ui-icon:before {
    content: " ";
    background: url(/wp-content/themes/glx-theme/dist/images/arrow.svg) no-repeat center $light-grey;
  }

  input:disabled {
    cursor: unset;
  }

  input:disabled+span.ui-icon {
    cursor: unset;
  }


  ul#ui-id-9 {
    box-sizing: border-box;
    left: -12% !important;
  }

  .btn {
    cursor: pointer;

    &:disabled {
      cursor: unset;
      background-color: #158603;
    }
  }

  input {
    border: 1px solid #58595b;
    box-sizing: border-box;
    color: #414042;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    padding: 13px 6px;
    width: 100%;
  }
}

#uldropdown ul {
  border: 1px solid gray;
  border-top: none;
  z-index: 6 !important;
}
.search_result_page {
padding-top: 15px;
}
.carsearch-modal.modal {
  padding-top: 13%;
}
.col-md-2.btn-change-model.clear.hide-on-mobile a {
  font-size: 88% !important;
  padding: 6px 20px 2px !important;
}

.btn-change-model {
  cursor: pointer;
  padding-right: 0;

  a {
    color: $grey !important;
    float: right;
  }
}

.hide-on-desktop,
.hide-on-load {
  display: none;
}

.products-container {
  .carsearch-result {
    margin-top: 10px;
  }
}

.row.change-model {
  margin-bottom: 10px;
}

.toggle-pro,
.toggle-premium,
.toggle-flash,
.toggle-echip {
  border: 2px solid black;
  clear: both;
  display: none;
  margin: 10px 0;
  padding: 10px;
}

.btn-toggle-pro,
.btn-toggle-premium,
.btn-toggle-flash,
.btn-toggle-echip {
  cursor: pointer;
}

.btn-info-toggle {
  color: $blue;
  display: block;
  padding-bottom: 15px;
  text-decoration: underline;
}

@include upToTablet {
  td.product-thumbnail {
    width: 20%;
  }

  section.section.hide-on-desktop {
    padding-top: 0;
  }

  .page-id-12606 .standard-page {
    padding-top: 0;
  }

  .m-btn-change-model {
    padding-right: 15px;
    margin-top: 15px;
    margin-bottom: 10px;

    a {
      display: table;
      float: none;
      position: relative;
      text-align: center;
      width: 100%;
    }
  }

  h5.type-col-title {
    margin-bottom: 0;
  }

  .hide-on {

    &-desktop {
      display: block;
    }

    &-load {
      display: none;
      height: 0;
      visibility: hidden;

      ul {
        display: block;
        visibility: visible;
      }
    }

    &-mobile {
      display: none;
    }
  }

  .toggle-pro,
  .toggle-premium,
  .toggle-flash,
  .toggle-echip {

    img {
      float: right;
      margin-top: 5px;
      width: 100%;
    }
  }

  .row.change-model {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}


@include upToDesktop {
  .model-col, .engine-col {
    margin-top: 10px;
  }

  .search-module section:nth-child(2) {
    padding: 0;
  }

  form.formcarsearch {
    z-index: 1;
    position: relative;
  }

  button.search.btn.btn--green {
    width: 100%;
    padding: 10px;
    margin-top: 8px;
  }
}

@include fromDesktop {

  .hide-on-mobile {
    display: table;
  }

  .search-module {
    .btn {
      height: 44px;
      padding: 6px 18px 2px;
    }

    section {
      display: grid;
      max-height: 20px;
      padding: 20px;
      position: static;
        left: 0;
        right: 0;
    }
  }

  ul {
    &.ajax_model {
      left: -4.3% !important;
      max-width: 41.8%;
    }

    &.ajax_engine {
      left: -1.5% !important;
      max-width: 41.8%;
    }
  }

  #uldropdown {

    .no_ajax_model {
      left: -8.4% !important;
      width: 53% !important;
    }

    .no_ajax_engine {
      left: -9.8% !important;
      width: 53% !important;
    }

    ul#ui-id-9 {
      left: -9.8% !important;
      width: 53% !important;
    }
    ul#ui-id-8 {
      width: 53% !important;
    }
  }
}
