.single-post {

	ul {
		@include ul;
	}

	&__title {
		padding: 40px 0 20px;
		position: relative;
		@include borderBottomLeft;

		&:after {
			background-color: $blue;
		}

		&--index {
			padding-top: 0;
		}
	}

	&__meta {
		margin-bottom: 15px;
		
		li {
			display: inline-block;
			float: left;
			margin-right: 10px;

			a {
				background-color: $green;
				color: $white;
				display: inline-block;
				font-size: 14px;
				font-weight: 600;
				padding: 3px 8px;
			}
		}
	}


}

.index {

	&__container {
		padding: 40px 0;
	}	

	&__title {
		margin-bottom: 25px;
		padding-bottom: 15px;
		position: relative;
		@include borderBottomLeft;

		&:after {
			background-color: $blue;
		}
	}

	article {
		margin-bottom: 30px;
	}
}

.nav-previous, 
.nav-next {
	background-color: $green;
	border: 2px solid $green;
	display: inline-block;
	font-family: $heading-font;
	font-size: 17px;
	font-weight: 500;
	padding: 6px 30px 2px;
	text-transform: uppercase;

	&:hover {
		background-color: $dark-green;
	}

	a {
		color: $white;
	}
}

.nav-previous {
	float: left;
}

.nav-next {
	float: right;
}