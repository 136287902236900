.b-container {
    height: $header-height;
    position: relative;
    	left: 0;
    	top: 0;
}

.b-menu {
	background: transparent;
	border: 0;
	height: 60px;
	padding-left: 0;
    padding-top: 23.5px;	
	width: 60px;
	
	&:hover {
		border: 0;
	}
}

.b-bun {
	margin: 0 auto;
}

body:after {
    background: none;
}

.b-bun--mid {
    top: 6px;
}

.b-bun--bottom {
    top: 12px;
}

.b-nav {
    background: $dark;
    height: 80vh;
	margin-top: 60px;
	overflow: scroll;
	width: 100%;

	li {
		border-bottom: 1px solid $grey;
		display: block;
		padding: 0;
		width: 100%;

		a {
			display: block;
			font-family: $heading-font;
			font-weight: 600;
			height: 60px;
			line-height: 60px;
			padding-left: 15px;
			text-transform: uppercase;
		}

		&.car {
			background: $green;
			text-align: center;

			a {
				color: $white;
				display: inline-block;
				padding-left: 65px;
    			position: relative;

				&:before {
					background: url('../images/icon-car.svg') center no-repeat;
					content: '';
					height: 60px;
					position: absolute;
						left: 0;
					width: 60px;
				}
			}
		}

		.mobile__parent {
			cursor: pointer;
			font-size: 17px !important;
			height: 60px;
			position: absolute;
				top: 0;
				right: 0;
			width: 60px;
			z-index: 2;
		}

		&:first-child {
			padding: 0;
		}

		.sub-menu {
			background: $white;
			display: none;

			a {
				color: $grey;
				font-family: $primary-font;
				font-weight: 300;
			}
		}

		&.active > .sub-menu {
    		display: block;
		}
	}
}