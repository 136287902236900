.two-columns {

	.btn {
		margin-top: 15px;
	}

	ul {
		@include ul;
	}

	a {
		color: $blue;
	}
}