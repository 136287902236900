#loginform {
	label {
		display: block;
	}

	.input {
		background: #fff;
	    border: 1px solid #dfdfdf;
	    font-size: 14px;
	    outline: none;
	    height: 30px;
	    width: 100%;
	}

	.button {
		background-color: $green;
		border: 0;
		box-shadow: none;
		color: $white;
		cursor: pointer;
		display: inline-block;
		font-family: $heading-font;
		font-size: 100%;
		font-weight: 700;
		left: auto;
    	line-height: 1;
    	overflow: visible;
		padding: .618em 1em;
		position: relative;
		text-decoration: none;
    	text-shadow: none;
		text-transform: uppercase;
		white-space: nowrap;	

		&:hover {
			background-color: $dark-green;
		}
	}
}

.footer {

	.gform_wrapper {

		input[type="text"] {
			background: #fff;
		    border: 1px solid #dfdfdf;
		    font-size: 14px;
		    outline: none;
		    height: 30px;
		    width: 100%;
		}

		.gfield_checkbox li input[type="checkbox"] {
			vertical-align: top;
		}

		li {
			margin-bottom: 10px;
		}

		.gfield_label {
			display: none !important;
		}	

		.gfield_error {
			input {
				border: 1px solid #ff0000;
			}
		}

		.validation_error {
			color: #ff0000;
			font-size: 14px;
		}

		.validation_message {
			display: none;
		}			
	}
}

