.bg-search {
	section:first-child {
    	display: none !important;
    }
}

.standard-page {
	padding-top: 10px;

	ul {
		@include ul;
	}
	
	a {
		color: $blue;
	}
}

.page-id-12606 .standard-page {
	padding-bottom: 20px;
}